import React, { Fragment } from "react"
import { useQuery } from "@apollo/client"
import styled from "styled-components"
import Heading from "mill/components/Heading"
import Imgix from "mill/components/Imgix"
import { paleBlue } from "mill/utils/colors"
import { AnchorTag } from "mill/utils/sharedStyles"
import Loading from "mill/components/Loader"
import LottieAnimation from "mill/components/LottieAnimation"
import seneca from "mill/components/LottieAnimation/seneca.json"
import senecaMobile from "mill/components/LottieAnimation/seneca-mobile.json"

const SenecaFrame = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  > div {
    height: 100vh;
    width: 100vw;
  }
`

import {
  Grid,
  SplitContainer,
  SplitHalf,
  ActionableWrapper
} from "mill/utils/shared/core"
import {
  LearningLibraryCard,
  PreviousQuestionsCard,
  ViewDashboardCard
} from "mill/components/ActionableCard"

import FETCH_CAMPAIGN_COMPLETE_DATA from "mill/graphql/FetchCampaignCompleteData"

const CampaignComplete = props => {
  const animationData = window.innerWidth < 832 ? senecaMobile : seneca
  const { loading, error, data } = useQuery(FETCH_CAMPAIGN_COMPLETE_DATA, {
    variables: { id: props.campaignId }
  })

  if (loading) return <Loading />
  if (error) return <p>Error :(</p>

  const {
    viewer: { learningLibraryEnabled, answeredQuestionCount },
    campaign: { id, name, mechanism }
  } = data
  const previousQuestionsAvailable = answeredQuestionCount > 0

  return (
    <Fragment>
      <SenecaFrame>
        <LottieAnimation
          animationId="seneca"
          animationData={animationData}
          scaleAspectRatio
        />
      </SenecaFrame>
      <Grid flow={"column"}>
        <SplitContainer>
          <SplitHalf hasImage centerContent backgroundColor={paleBlue}>
            <Imgix
              srcPath={"static/target-arrows-bullseye.png"}
              parameters={"fit=fill&fill-color=0000"}
              maxWidth={640}
              maxHeight={480}
            />
          </SplitHalf>
          <SplitHalf hasText>
            <div>
              <Heading size={1} rank={1}>
                Congrats!
              </Heading>
              <Heading size={2} rank={2}>
                {name
                  ? `You have completed ${name}`
                  : "You have completed the campaign"}
              </Heading>
              <p>
                Until next time, we suggest checking out your stats and
                reviewing past explanations to keep your learning fresh!
              </p>
              {window.THEME_NAME !== "auspost" && (
                <p>
                  Interested in learning? Got a thirst for knowledge? So do
                  we... check out our{" "}
                  <AnchorTag href="https://www.yarno.com.au/blog">
                    blog
                  </AnchorTag>
                  !
                </p>
              )}
              <ActionableWrapper>
                {learningLibraryEnabled && <LearningLibraryCard />}
                {previousQuestionsAvailable && <PreviousQuestionsCard />}
                <ViewDashboardCard campaignID={id} mechanism={mechanism} />
              </ActionableWrapper>
            </div>
          </SplitHalf>
        </SplitContainer>
      </Grid>
    </Fragment>
  )
}

export default CampaignComplete
