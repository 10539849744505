import React from "react"
import styled, { withTheme } from "styled-components"
import { headingFont } from "mill/utils/settings"
import Link from "shared/components/Link"
import { darken } from "polished"

const WrappedLink = styled(Link)`
  text-decoration: none;
  display: block;
`
const Card = styled.div`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "rgb(248, 248, 248)"};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-left: 3px solid
    ${props => (props.color ? props.color : props.theme.colors.secondary)};
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  max-width: 37.5rem;
  transition: background-color 0.1s ease-out, border-left-width 0.1s ease-out;

  &:hover {
    cursor: pointer;
    border-left-width: 4px;
    transition: background-color 0.1s ease-in, border-left-width 0.1s ease-in;
    background-color: ${props =>
      props.backgroundColor
        ? darken(0.04, props.backgroundColor)
        : darken(0.02, "rgb(248, 248, 248)")};
    i {
      color: ${props =>
        props.color ? props.color : props.theme.colors.secondary};
    }
  }
`

const Title = styled.h3`
  font-family: ${headingFont};
  font-weight: bold;
  font-size: 13px;
  color: ${props => (props.fontColor ? props.fontColor : "rgb(129,129,129)")};
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin: 0;

  i {
    font-size: 11px;
    position: relative;
    top: -1px;
    margin-left: 0.2rem;
  }
`

const Description = styled.p`
  font-size: 1.4rem;
  margin: 0.2rem 0 0 0;
  color: ${props => (props.fontColor ? props.fontColor : "rgb(142, 144, 145)")};
  line-height: 1.4em;
`

const ActionableCard = props => {
  if (props.url) {
    return (
      <Card color={props.color} backgroundColor={props.backgroundColor}>
        <WrappedLink replace data-qa={props["data-qa"]} to={props.url}>
          <Title fontColor={props.fontColor}>
            {props.title} <i className="fa fa-chevron-right-fas" />
          </Title>
          <Description fontColor={props.fontColor}>
            {props.description}
          </Description>
        </WrappedLink>
      </Card>
    )
  } else {
    return (
      <Card
        data-qa={props["data-qa"]}
        color={props.color}
        onClick={props.handleClick}
        backgroundColor={props.backgroundColor}>
        <Title fontColor={props.fontColor}>
          {props.title} <i className="fa fa-chevron-right-fas" />
        </Title>
        <Description fontColor={props.fontColor}>
          {props.description}
        </Description>
      </Card>
    )
  }
}

export const LearningLibraryCard = withTheme(props => {
  return (
    <ActionableCard
      color={props.theme.colors.tertiary}
      title="View Learning Library"
      description="Take your knowledge to the next level by reading in-depth articles."
      url="/learning-library"
    />
  )
})

export const PreviousCampaignsCard = withTheme(props => {
  return (
    <ActionableCard
      color={props.theme.colors.secondary}
      title="View previous results"
      description="See how you did on your previous campaigns by viewing their dashboards"
      url="/dashboard"
    />
  )
})

export const PreviousQuestionsCard = withTheme(props => {
  return (
    <ActionableCard
      color={props.theme.colors.quaternary}
      title="View previous questions"
      description="Review questions you’ve previously answered by topic."
      url="/learning-library/topics"
    />
  )
})

export const TourCard = withTheme(props => {
  return (
    <ActionableCard
      data-qa="take-tour"
      color={props.theme.colors.primary}
      title="Take tour"
      description={`Let Seneca take you over everything you need to know to get the most out of ${window.PLATFORM_NAME}.`}
      url="/tour"
    />
  )
})

export const ViewDashboardCard = withTheme(
  ({ campaignID, mechanism, theme }) => {
    const copy =
      mechanism == "burst"
        ? "See how you did on the burst."
        : "See how you're going on the campaign."
    return (
      <ActionableCard
        data-qa="view-dashboard"
        color={theme.colors.primary}
        title="View Campaign Dashboard"
        description={copy}
        url={`/c/${campaignID}/dashboard`}
      />
    )
  }
)

export default ActionableCard
